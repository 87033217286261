export default ({ img }) => {
  const imgUrl = process.env.VUE_APP_PRINT_IMAGE_FOLDER;
  let template = `
        <div class="page-break">
            <style type="text/css">${staticPageStyles}</style>
            <div
              id="static-page"
              style="background-image: url('${imgUrl}/${img}.png')">
            </div>
        </div>
    `;
  return template;
};

export const staticPageStyles = `
#static-page,
.static-page {
  width: 100%;
  height: 1337px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top left;
}
`;
