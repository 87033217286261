import { serviceStyles, serviceList as servicesTBody } from "./service.js";
import { productStyles, productsList as productsTBody } from "./products.js";

const signatureBlock = (HT, REMISE, TVA, TTC) => `
  <div class="fz-12 text--blue fw-600">Date et signature du client, précédée de la mention manuscrite : </div>
  <div class="fz-12 text--orange fw-500">« Devis reçu avant début des travaux, bon pour accord »</div>
  <div class="signature-wrapper">
    <div class="signature"></div>
    <div class="total">
      <div class="total-item">
        <div class="fz-18 text--blue fw-600">TOTAL HT:</div>
        <div class="fz-18 text--orange fw-600">${HT} €</div>
      </div>
      <div class="total-item">
        <div class="fz-18 text--blue fw-600">TVA 10% :</div>
        <div class="fz-18 text--orange fw-600">${TVA} €</div>
      </div>
      ${
        REMISE == "0,00"
          ? ""
          : `<div class="total-item">
          <div class="fz-18 text--blue fw-600">REMISE:</div>
          <div class="fz-18 text--orange fw-600">${REMISE} €</div>
        </div>`
      }

      <div class="total-item">
        <div class="fz-18 text--blue fw-600">TOTAL TTC:</div>
        <div class="fz-18 text--orange fw-600">${TTC} €</div>
      </div>
    </div>
  </div>
`;

export default ({ roomList, productsList, HT, REMISE, TTC, TVA }) => {
  let templateService = `
    <div class="">
      <style>${serviceStyles}</style>
      <div id="offer-sum-page">
        
        <main class="page2-content">
          <table class="main-table">
            <thead>
              <tr>
                  <th class="descr">Descriptif de la prestation et/ou réalisation de rénovation</th>
                  <th class="line-break single-price">
                      Prix Unitaire HT
                      dont fourniture(s)
                  </th>
                  <th class="quantity">Quantité</th>
                  <th class="units">Unité</th>
                  <th class="total-sum">Total TTC</th>
              </tr>
            </thead>
            ${servicesTBody(roomList)}
          </table>
          <div class="page-break-inside-avoid" style="padding-top: 25px;">
            ${signatureBlock(HT, REMISE, TVA, TTC)}
          </div>
        </main>
      </div>
    </div> 
  `;

  let templateProducts = `
    <div class="">
      <style>${productStyles}</style>
      <div id="offer-sum-page">
        <main id="offer-products" class="page2-content">
          <table class="main-table">
            ${productsTBody(productsList)}
          </table>
        </main>
      </div>
    </div>
  `;

  return {
    services: templateService,
    products: templateProducts,
  };
};
