import orderBy from "lodash.orderby";
import { CategoryService } from "@/services/category.service.js";
import { ProjectService } from "@/services/project.service.js";
import { QuotaRoomProductsService } from "@/services/product.service.js";
import { RoomServicesService } from "@/services/roomServices.sevice.js";
import { QuotaService } from "@/services/quotas.service.js";
import { priceFormat } from "@/utils/index.js";
import { mapState, mapGetters, mapActions } from "vuex";
import { format } from "date-fns";

import pdfGeneration from "./../PdfGeneration/index.js";

export default {
  data() {
    return {
      quotas: [],
      loadingGenerateButton: false,
      subcategories: [],
      quotaCategories: [],
      quotaServices: [],
      quotaCustomServices: [],
      quotaProducts: [],
      units: [],
      projectHistory: [],
    };
  },
  computed: {
    ...mapGetters(["quota/isQuotaSigned"]),
    ...mapState(["quota/quotaData"]),
    itemPrice() {
      return (item) => {
        let priceUnit = item?.price || 0;
        if (priceUnit !== 0 && this.itemAmount(item) !== 0) {
          priceUnit = item?.price / this.itemAmount(item);
        }
        return priceUnit;
      };
    },
    itemAmount() {
      return (item) => {
        let amount = item?.amount || 0
        let additionalUnits = item?.service?.additional_units || 0
        if (item.type === "product" || item.type === "custom-product") {
          additionalUnits = 0
        }
        return additionalUnits !== 0 ? amount * (additionalUnits !== 0 ? additionalUnits / 100 : 1) + amount : amount
      }
    },
    serviceUnit() {
      return (unitId) => {
        return (
          this.units?.find((unit) => unit.id === unitId)?.short_name || "-"
        );
      };
    },
    getName() {
      return (item) => {
        let name = `${item?.service?.name ? item?.service?.name : "-"}`
        if (item.type === "service") {

          name = `
            <div>${item?.service?.name}</div>
            <div class="fs-i fw-500">Type: ${item?.service_combination?.name ?? 'Standard'}</div>
            <div class="fs-i fw-500">Référence: ${item?.service?.reference ?? ''}</div>
            <div class="fs-i fw-500" style="display: ${item?.service?.description ? 'block' : 'none'}">${item?.service?.description ?? ''}</div>
          `
        }
        if (item.type === "custom-service") {
          name = `
            <div>${item?.service?.name}</div>
            <div class="fs-i fw-500" style="display: ${item?.service?.description ? 'block' : 'none'}">${item?.service?.description ?? ''}</div>
          `
        }
        if (item.type === "product") {
          name = `
            <div>${item?.product?.name}</div>
            <div class="fs-i fw-500" style="display: ${item?.product?.service_combination ? 'block' : 'none'}">Type: ${item?.product?.service_combination?.name ?? ''}</div>
            <div class="fs-i fw-500">Référence: ${item?.product?.reference ?? ''}</div>
            <div class="fs-i fw-500">Description: ${item?.product?.description ?? ''}</div>
          ` || "-"
        }
        if (item.type === "custom-product") {
          name = `
            <div>Fourniture choisie par le client</div>
            <div class="fs-i fw-500">
              Nom du fournisseur: ${item.custom_product.name ?? ''}<br> 
              Marque: ${item.custom_product.mark ?? ''}<br>
              Référence Produit: ${item.custom_product.reference ?? ''}<br>
              Prix du produit: ${item.custom_product.client_price ?? priceFormat(0)} €<br>
              Vos commentaires: ${item.custom_product.description ?? ''}
            </div>
          `
        }
        return name
      }
    },
    quotaRoom() {
      let quota = this.quotas.find(
        (quota) => quota.id === +this.$route.params.quotaId
      );
      let rooms = quota ? quota.quota_rooms : [];
      return rooms;
    },
    quotaRoomsTable() {
      if (this["quota/isQuotaSigned"]) {
        return this.$store.state.quota.quotaData;
      }
      let rooms = this.quotaRoom;
      let products = [{
          name: "Fournitures",
          priceWithTax: 0,
          categories: [],
      }];
      let HT = 0;
      let TTC = 0;
      let REMISE = 0;

      let items = [];

      if (!!this.quotaServices) {
        items?.push(
          ...this.quotaServices.map((s) => {
            let categoryCat = this.subcategories?.find(
              (cat) => cat.id === s.category_id
            );
            return {
              ...s,
              type: "service",
              category_id: categoryCat?.id || null,
              category_name: categoryCat?.name || null,
              category_parent_id: categoryCat?.parentId || null,
              category_parent_name: categoryCat?.parentName || null,
            };
          })
        );
      }

      items.push(...this.quotaProducts
        ?.filter((product) => product.quota_room_id !== null && product.quota_room_service_id !== null)
        .map((product) => {
          let service = this.quotaServices?.find(
            (service) => service.id === product.quota_room_service_id
          );

          let categoryCat =
            this.subcategories?.find(
              (cat) => cat.id === service?.category_id
            ) || null;
          return {
            ...product,
            type: product?.custom_product_id && !product?.service_product_id ? "custom-product" : "product",
            category_id: categoryCat?.id || null,
            category_name: categoryCat?.name || null,
            category_parent_id: categoryCat?.parentId || null,
            category_parent_name: categoryCat?.parentName || null,
            amount: service?.amount || 0,
          };
        })
      );

      let list = rooms?.map((room) => {
        let roomItemOfProductList = {
          roomName: room.name,
          items: [],
          priceWithTax: 0
        };
        let roomPriceWithTax = 0;
        let customServicePriceWithTax = 0
        let displayCustomServices = false;

        let customServices = this.quotaCustomServices
          ?.filter((s) => s.quota_room_id === room.id)
          .map((item) => {
            displayCustomServices = true;
            customServicePriceWithTax += item?.price_with_tax || 0;
            roomPriceWithTax += item?.price_with_tax || 0;
            return [
              {
                key: "name",
                value: this.getName(item),
              },
              {
                key: "price",
                value: priceFormat(this.itemPrice(item)) + " €",
              },
              {
                key: "amount",
                value: this.itemAmount(item)
              },
              {
                key: "unit",
                value: "u",
              },
              {
                key: "discount",
                value: "-",
              },
              {
                key: "total",
                value: priceFormat(item?.price_with_tax) + " €",
              },
            ];
          })


        let roomItem = {
          editIconAction: {
            name: "RoomId",
            params: {
              roomId: room.id,
            },
            query: {
              project_id: this.currentProjectId,
              quota_id: this.$route.params.quotaId,
            },
          },
          roomId: room.id,
          name: room.name,
          price: roomPriceWithTax,
          customServices: {
            name: "Prestations supplémentaires",
            price: 0,
            items: [...customServices],
          },
          categories: [
            ...new Set(
              items
                .filter((item) => item.quota_room_id === room.id)
                .map((item) => item.category_parent_id)
            ),
          ].map((key, index) => {
            let priceWithTax = 0;
            let categoryIndex = index + 1;
            let subCat = items.filter((item) => {
              if (
                item.quota_room_id === room.id &&
                item.category_parent_id === key
              ) {
                priceWithTax += item?.discount_price || 0;
                roomPriceWithTax += item?.discount_price || 0;
                return true;
              }
            });
            return {
              categoryIndex: categoryIndex,
              category_id: key,
              name: !key ? null : subCat[0]?.category_parent_name,
              price: priceFormat(priceWithTax) + " €",
              subcategories: [
                ...new Set(subCat.map((item) => item.category_id)),
              ].map((key, index) => {
                let subcategoryIndex = `${categoryIndex}.${index + 1}`;
                let quota_room_service = [];
                let quota_room_products = [];
                let items = subCat
                  .filter((item) => item.category_id === key)
                  .forEach((item) => {
                    if (item.type === "service") {
                      quota_room_service.push({
                        ...item,
                        position: item?.position?.position || 0,
                      });
                    }
                    if (item.type === "product") {
                      quota_room_products.push(item);
                    }
                  });
                quota_room_service = orderBy(
                  quota_room_service,
                  ["position"],
                  ["asc"]
                );
                quota_room_products.forEach((product) => {
                  let quotaRoomServiceIndex = quota_room_service.findIndex(
                    (service) => product.quota_room_service_id === service.id
                  );
                  if (quotaRoomServiceIndex || quotaRoomServiceIndex === 0) {
                    quota_room_service?.splice(
                      quotaRoomServiceIndex + 1,
                      0,
                      product
                    );
                  }
                });
                items = [...quota_room_service];
                return {
                  subcategoryIndex: subcategoryIndex,
                  sub_category_id: key,
                  name: !key ? null : items[0]?.category_name,
                  items: items.map((item, index) => {
                    HT += item?.discount_price_without_tax || 0;
                    TTC += item?.discount_price || 0;
                    REMISE += item?.price_with_tax - item?.discount_price || 0;

                    if (item.type === "custom-product") {
                      roomItemOfProductList.items.push({
                        priceWithTax: priceFormat(item.price_with_tax),
                        ...item.custom_product,
                        client_price: priceFormat(item.custom_product?.client_price),
                        image: `${process.env.VUE_APP_PRINT_IMAGE_FOLDER}/custom-product.jpg`
                      })
                      roomItemOfProductList.priceWithTax += item?.price_with_tax || 0;
                      products[0].priceWithTax += item?.price_with_tax || 0;
                    }

                    if (item.type === "product") {
                      roomItemOfProductList.items.push({
                        priceWithTax: priceFormat(item.price_with_tax),
                        ...item.product
                      })
                      roomItemOfProductList.priceWithTax += item?.price_with_tax || 0;
                      products[0].priceWithTax += item?.price_with_tax || 0;
                    }

                    const distance = {
                      distanceTop: item.type === 'service' && index > 0,
                      distanceBottom: (item.type === 'product' || item.type === 'custom-product') && index === items.length - 1 ,
                      borderBottom: (item.type === 'product' || item.type === 'custom-product') || items[index+1]?.type === 'service' || index === items.length - 1
                    }

                    let hasDiscount = item.discount_fixed || item.discount_percentage
                    let discount = 0
                    let discount_number = 0
                    let discount_type = ''
                    if(item?.discount_fixed) {
                      discount = `${priceFormat(item?.discount_fixed)}€`
                      discount_type = 'fix'
                      discount_number = item?.discount_fixed
                    } else if (item?.discount_percentage) {
                      discount = `${priceFormat(item?.discount_percentage)}%`
                      discount_type = 'percent',
                      discount_number = item?.discount_percentage
                    } else  {
                      discount = ''
                    }
  
                    let discountPrice = !hasDiscount ? priceFormat(item?.discount_price) + " €" : `
                          <div class="">
                            <div>
                              <span class="old-price">${priceFormat(item?.price_with_tax) + " €"}</span>
                            </div>
                            <div>
                              <span class="discount-price">${priceFormat(item?.discount_price) + " €"}</span>
                            </div>
                          </div>
                        `

                    let discountPriceValue =
                      !hasDiscount ?
                        {
                          type: "single",
                          data: priceFormat(item?.discount_price) + " €"
                        } :
                        {
                          type: "multiple",
                          data: {
                            old_price: `${priceFormat(item?.price_with_tax) + " €"}`,
                            discount_price: `${priceFormat(item?.discount_price) + " €"}`,
                            discount_type,
                            discount_number
                          }
                        };
                    return [
                      {
                        key: "name",
                        value: this.getName(item),
                        ...distance
                      },
                      {
                        key: "price",
                        value: priceFormat(this.itemPrice(item)) + " €",
                        ...distance
                      },
                      {
                        key: "amount",
                        value: this.itemAmount(item),
                        ...distance
                      },
                      {
                        key: "unit",
                        value: `${this.serviceUnit(item?.service?.unit_id)}`,
                        ...distance
                      },
                      {
                        item,
                        key: "total",
                        value: discountPrice,
                        discountPriceValue: discountPriceValue,
                        ...distance
                      },
                    ];
                  }),
                };
              }),
            };
          }),
        };

        roomItem.price = priceFormat(roomPriceWithTax) + " €";
        roomItem.customServices.price = priceFormat(customServicePriceWithTax) + " €";

        if (!displayCustomServices) {
          delete roomItem.customServices;
        }

        products[0].categories.push({
          ...roomItemOfProductList,
          priceWithTax: priceFormat(roomItemOfProductList.priceWithTax),
        })
        return roomItem;
      });

      products[0].priceWithTax = priceFormat(products[0].priceWithTax)

      return {
        HT: priceFormat(HT),
        REMISE: priceFormat(REMISE),
        TTC: priceFormat(TTC),
        TVA: priceFormat((TTC - HT)),
        numberHT: HT,
        numberREMISE: REMISE,
        numberTTC: TTC,
        numberTVA: TTC - HT,
        list: list,
        products: products
      };
    },
  },
  watch: {
    quotas(val) {
      if (!val) return false;
      if (!this["quota/isQuotaSigned"]) {
        Promise.all([
          this.getCategoriesTree(),
          this.getQuotaRoomServices(),
          this.getQuotaRoomProducts(),
          this.getRoomCategories(),
          this.getUnits(),
        ])
          .then(() => { })
          .catch((ex) => {
            console.log(ex);
            this.$store.commit(
              "snackbarModule/SET_ERROR_API",
              this.$t("snackbar.error.base")
            );
          });
      }
      if (this["quota/isQuotaSigned"]) {
        this["quota/getQuotaData"]();
      }
    },
  },
  created() {
    this["quota/getPdfContent"]();
    ProjectService.getProjectHistory({ quota_id: this.$route.params.quotaId })
      .then(({ data }) => { this.projectHistory = data })
      .catch(ex => console.log(ex))
  },
  methods: {
    ...mapActions(["quota/getQuotaData", "quota/getPdfContent"]),
    getCategoriesTree() {
      return CategoryService.getCategoriesTree().then(({ data }) => {
        this.subcategories = [];
        if (!data) return false;
        let tree = { ...data }
        tree = Object.keys(data).map(catKey => {
          let cat = { ...data[catKey] }
          let subcat = cat.children ? Object.keys(cat.children).map(subCatKey => {
            return {
              ...cat.children[subCatKey]
            }
          }) : []
          cat.children = subcat
          return cat
        });

        tree?.filter((c) => c.children)
          .forEach((cat) => {
            let currentCat = { ...cat };
            this.subcategories.push(
              ...currentCat.children.map((c) => {
                return {
                  ...c,
                  parentName: cat.name,
                  parentId: cat.id,
                };
              })
            );
          });
      });
    },
    getQuotaRoomServices() {
      if (this.quotaRoom.length === 0) return false;
      return RoomServicesService.getQuotaRoomService(
        this.quotaRoom.map((room) => room.id)
      ).then(({ data }) => {
        this.quotaServices = data.filter(
          (s) => s.category_id !== 1 && s.quota_room_id !== null
        );
        this.quotaCustomServices = data.filter((s) => s.category_id === 1).map(s => ({ ...s, type: 'custom-service' }));
      });
    },
    getQuotaRoomProducts() {
      if (this.quotaRoom.length === 0) return false;
      return QuotaRoomProductsService.getProducts(
        this.quotaRoom.map((room) => room.id)
      ).then(({ data }) => {
        this.quotaProducts = data;
      });
    },
    getRoomCategories() {
      if (this.quotaRoom.length === 0) return false;
      return CategoryService.getQuotaRoomsCategories(
        this.quotaRoom.map((room) => room.id)
      ).then(({ data }) => {
        this.quotaCategories = data;
      });
    },
    getUnits() {
      return RoomServicesService.getUnits().then(({ data }) => {
        this.units = data;
      });
    },
    pdfGeneration() {
      this.loadingGenerateButton = true;
      let updateAt = orderBy(this.projectHistory, ['date'], ['desc'])?.[0]?.date || null;
      let projectInfo = this.$store.getters['project/getCurrentProject'];
      let quotaInfo = this.$store.getters['quota/getCurrentQuota'];
      let name = `N° du Devis :${this.$route.params.quotaId || "0"}`;
      let companyName = this.$store.state.platformParameters?.name || "-";
      let pdfContent = this.$store.state.quota.pdfContent;

      let pdfString = pdfGeneration({
        quotaNumber: this.$route.params.quotaId || "0",
        quotaName: quotaInfo?.name || "-",
        clientFullName: projectInfo?.user?.full_name || '',
        clientAddress: `${projectInfo?.address_construction || ''}${projectInfo?.address_construction ? ', ' : ''}${projectInfo?.city || ''} ${projectInfo.postal_code}`,
        currentProject: projectInfo,
        additionalInfo: {
          hours_of_execution: quotaInfo?.hours_of_execution || 0,
          createAt: quotaInfo?.create_date || null,
          updateAt: updateAt,
          startWork: quotaInfo?.start_work_date || null,
          visitDate: quotaInfo?.visit_date || null,
          paymentTerms1: quotaInfo?.price_stage_1 || 0,
          paymentTerms2: quotaInfo?.price_stage_2 || 0,
          paymentTerms3: quotaInfo?.price_stage_3 || 0
        },
        offerSumPage: {
          title: `N° du Devis :${this.$route.params.quotaId || "0"}`,
          number: this.$route.params.quotaId || "0",
          roomList: this.quotaRoomsTable.list,
          productsList: this.quotaRoomsTable.products,
          HT: this.quotaRoomsTable.HT,
          REMISE: this.quotaRoomsTable.REMISE,
          TTC: this.quotaRoomsTable.TTC,
          TVA: this.quotaRoomsTable.TVA,
          numberHT: this.quotaRoomsTable.numberHT,
          numberREMISE: this.quotaRoomsTable.numberREMISE,
          numberTTC: this.quotaRoomsTable.numberTTC,
          numberTVA: this.quotaRoomsTable.numberTVA,
          imprintInPDF: quotaInfo?.imprint || "imprint"
        },
        companyName,
        pdfContent,
      }).getData();
      // console.log(`${projectInfo?.address || ''} ${projectInfo.postal_code} ${projectInfo?.city || ''}`)
      QuotaService.generatePdf({ html: pdfString, name, details: {
        id: quotaInfo?.id || "",
        date: this.quotaRoomsTable.dateSigned || format(new Date(), "dd/MM/yyyy"),
        manager: projectInfo?.manager?.full_name 
      } }).finally(
        () => (this.loadingGenerateButton = false)
      );
    },
  }
}