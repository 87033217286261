import { protectedAxios } from "./helpers";

export class CategoryService {

  static getRoomCategories(roomType) {
    return protectedAxios.get(`/rooms/${roomType}`);
  }
  static getQuotaRoomsCategories(rooms_ids) {
    return protectedAxios.get(`/quota_room_categories/`, {
      params: {
        quota_room_id: rooms_ids?.join(',')
      }
    });
  }
  static createQuotaRoomsCategories(list) {
    return protectedAxios.post(`/quota_room_categories/`, list);
  }
  static getCategoriesTree() {
    return protectedAxios.get(`/categories/tree`);
  }
  static getCategoryByid(id) {
    return protectedAxios.get(`/categories/${id}`);
  }
}
