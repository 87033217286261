import Mustache from "mustache";

export default ({ clientFullName, quotaNumber, clientAddress, companyName }) => {
  const imgUrl = process.env.VUE_APP_PRINT_IMAGE_FOLDER;
  let template = `
        <div class="">
            <style type="text/css">${page3Styles}</style>
            <div id="page-3" style="background-image: url('${imgUrl}/page-3-bg.png');">
              <div class="page-3-main">
                  <div class="page-3-header">
                      <div class="page-3-header--title">
                          <span>POURquoi</span>
                          faire<br>
                          VOS TRAVAUX AVEC<br>
                          {{companyName}}
                      </div>
                      <div class="page-3-header--content">
                          <div>
                              Les artisans de l’équipe <span class="text--orange fw-700">{{companyName}}</span> sont animés par 
                              la passion de leur métier. 
                              Il nous tient à cœur de réaliser chaque projet de rénovation 
                              avec <span class="text--orange fw-700">le plus grand soin</span>. Chaque nouvelle mission nous offre 
                              l’opportunité de nous dépasser et de faire encore mieux.
                          </div>
                          <div>
                              Depuis de nombreuses années nous intervenons <span class="text--orange fw-700">uniquement sur des 
                              chantiers de haut standing</span>. Notre expérience, nos garanties et le 
                              respect scrupuleux des normes font de <span class="text--orange fw-700">{{companyName}}</span> l’entreprise idéale 
                              pour mener vos travaux en toute sérénité.
                          </div>
                      </div>
                  </div>

                  <div class="page-3-body">
                      <div class="page-3-body--title">
                          Quand<br>
                          recevrez-vous<br>
                          <span>votre planning ?</span>
                          <div class="image-wrapper">
                              <img src="${imgUrl}/rectangle.png" alt="">
                          </div>
                      </div>
                      <div class="page-3-body--content">
                          <p>
                              <span class="text--orange fw-700">Le planning</span> de votre chantier sera défini avec précision. 
                              Il vous sera transmis sous <span class="text--orange fw-700">72h après</span> le démarrage des travaux. 
                              Le suivi complet du projet, du départ à sa livraison, sera pris 
                              en charge par notre conducteur de travaux, dans <span class="text--orange fw-700">le respect des 
                              délais</span> et du budget convenus. Un rapport vous sera délivré suite à 
                              chaque réunion de chantier.
                          </p>

                          <p>
                              Certains postes de cette offre, matériaux ou fournitures, ne sont pas 
                              <span class="text--orange fw-700">forcément finalisés</span> au moment de la signature. Si tel est le cas, pas 
                              de soucis. En cours de route, la plupart des lots sont <span class="text--orange fw-700">modifiables 
                              d’un commun accord</span>.
                          </p>

                          <p>
                              Parlez-en à votre conseiller travaux ou à votre conducteur des travaux 
                              si vous avez déjà signé votre devis.
                          </p>
                      </div>
                  </div>
                  
                  
              </div>
          </div>
        </div>
    `;
  return Mustache.render(template, {
    clientFullName: clientFullName,
    clientAddress: clientAddress,
    quotaNumber: quotaNumber,
    companyName,
  });
};

const page3Styles = `
#page-3,.page-3{height: 1337px!important;background-position:top;background-repeat:no-repeat;background-size:100% auto}#page-3,#page-3-main,.page-3,.page-3-main{height:100%;position:relative}#page-3-header--title,.page-3-header--title{color:#fff;font-size:55px;font-weight:700;left:14%;line-height:1.245;position:absolute;text-transform:uppercase;top:6%}#page-3-header--title span,.page-3-header--title span{background:#fff;color:#f29332;display:inline-block;line-height:1;margin-bottom:10px;padding:8px 6px 6px}#page-3-header--content,.page-3-header--content{-webkit-box-pack:justify;-ms-flex-pack:justify;color:#000;display:-webkit-box;display:-ms-flexbox;display:flex;justify-content:space-between;left:12%;position:absolute;right:13%;top:33%}#page-3-header--content>div,.page-3-header--content>div{-webkit-box-flex:0047%;-ms-flex:0047%;flex:0047%;font-size:17px;line-height:1.25;max-width:47%;text-align:justify}#page-3-body--title,.page-3-body--title{color:#000;font-size:55px;font-weight:700;left:4%;line-height:1.1;position:absolute;text-transform:uppercase;top:54%}#page-3-body--title span,.page-3-body--title span{color:#f29332}#page-3-body--title .image-wrapper,.page-3-body--title .image-wrapper{margin-top:25px}#page-3-body--title .image-wrapper img,.page-3-body--title .image-wrapper img{height:auto;width:93%}#page-3-body--content,.page-3-body--content{color:#000;font-size:17px;left:58%;line-height:1.3;position:absolute;right:6%;text-align:justify;top:55%}#page-3-body--content p,.page-3-body--content p{margin-bottom:52px}
`;
