import Mustache from "mustache";

export default (ownerfullName, main_logo_url) => {
  const imgUrl = process.env.VUE_APP_PRINT_IMAGE_FOLDER;
  let template = `
        <div class="page-break">
            <style type="text/css">${homePageStyles}</style>
            <div id="home-page">
                <div class="home-page--main" style="background-image: url('${imgUrl}/A4-1.png')">
                  <div style="position: absolute; top: 6%; left: 7%; right: 47%; text-align: center;">
                    <img style="max-width: 200px; height: auto;" src="${main_logo_url}">
                  </div>
                  <div class="owner">
                    <span class="text--orange">{{ownerfullName}}</span>
                  </div>
                </div>
            </div>
        </div>
    `;
  return Mustache.render(template, {
    ownerfullName: ownerfullName,
  });
};

export const homePageStyles = `
#home-page,.home-page{-webkit-box-orient:vertical;-webkit-box-direction:normal;display:-webkit-box;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;height:1400px}#home-page--main,.home-page--main{-webkit-box-flex:1;background-image:url(${process.env.VUE_APP_PRINT_IMAGE_FOLDER}/home-bg.png});background-position:bottom;background-repeat:no-repeat;background-size:cover;-ms-flex:1;flex:1;padding-bottom:11px;padding-left:48px;padding-top:38px;position:relative}#home-page--main .owner,.home-page--main .owner{font-size:29px;font-weight:700;left:8%;line-height:1.2;position:absolute;text-align:center;top:69.5%;width:430px}
`;
