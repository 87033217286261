import Mustache from "mustache";

export default ({ clientFullName, quotaNumber, clientAddress, quotaName }) => {
  const imgUrl = process.env.VUE_APP_PRINT_IMAGE_FOLDER;
  let template = `
        <div class="page-break">
            <style type="text/css">${page2Styles}</style>
            <div id="page-2" style="background-image: url('${imgUrl}/page-2-bg.png');">
                <div class="page-2--main">
                    <div class="page-title">
                        Offre chiffrée</br>
                        pour votre</br>
                        projet de</br>
                        <span>RéNOVATION</span>
                    </div>
                    <div class="page-description">
                      <p class="text--orange fw-700">{{clientFullName}},</p>
                      <p>
                          Nous sommes heureux de vous faire parvenir votre devis concernant 
                          <span class="text--orange fw-700">la rénovation de {{quotaName}}</span>, situé à l’adresse : 
                          <span class="fw-700">{{clientAddress}}</span>.
                      </p>

                      <p>
                          Le chiffrage de ce devis repose sur les informations collectées par notre conseiller 
                          travaux. En cas d’erreur ou d’omission de notre part, pas de panique !
                      </p>


                      <p>
                          Il vous suffit de transmettre vos remarques <span class="text--orange fw-700">à votre conseiller dédié</span> et nous apporterons 
                          les corrections requises dans les plus brefs délais.
                      </p>
                  </div>
                    
                </div>
            </div>
        </div>
    `;
  return Mustache.render(template, {
    clientFullName: clientFullName,
    clientAddress: clientAddress,
    quotaNumber: quotaNumber,
    quotaName,
  });
};

export const page2Styles = `#page-2,.page-2{height: 1337px!important;background-position:top;background-repeat:no-repeat;background-size:100% auto}#page-2,#page-2--main,.page-2,.page-2--main{height:100%;position:relative}#page-2--main .page-title,.page-2--main .page-title{color:#fff;font-size:55px;font-weight:700;left:6%;line-height:1.245;position:absolute;text-transform:uppercase;top:29%}#page-2--main .page-title span,.page-2--main .page-title span{background:#fff;color:#f29332;display:inline-block;line-height:1;margin-top:10px;padding:8px 6px 6px}#page-2--main .page-description,.page-2--main .page-description{color:#000;font-size:17px;position:absolute;right:5%;top:26%;width:29%}#page-2--main .page-description p,.page-2--main .page-description p{line-height:1.25;margin-bottom:25px}`;
